import { combineReducers, Reducer, AnyAction } from 'redux'
import { RootStateInterface } from '@/states/interfaces/rootState'

import contentsReducer from './contents.reducer'
import layoutReducer from './layout.reducer'

const rootReducer: Reducer<RootStateInterface, AnyAction> =
  combineReducers<RootStateInterface>({
    contents: contentsReducer,
    layout: layoutReducer,
  })

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
