import { EditorHtml } from '@/components/EditorHtml'
import { getContentsDataItem } from '@/states/actions/contents.actions'
import { NoticesActivitiesInterface } from '@/types'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'

const NoticesItem = ({
  title,
  path,
  devKey,
}: {
  title: string
  path: string
  devKey: string
}) => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()

  // State
  const [notice, setNotice] = React.useState<NoticesActivitiesInterface | null>(
    null
  )

  useEffect(() => {
    getContentsDataItem(id, devKey)
      .then((res) => {
        setNotice(res.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])

  return (
    <>
      <div className="px-5 py-3 bordered-b">
        <h1 className="para-lg color-primary">{title}</h1>
      </div>
      {notice ? (
        <div className="pt-10 pb-48 container mx-auto max-w-[1000px] px-5 lg:px-0">
          <div className="items-center justify-between pb-3 text-lg font-bold border-b lg:flex color-primary border-primary">
            <h2>{notice.data.title.KO}</h2>
            <div>
              {moment(notice.data.created_date, 'YYYYMMDDThh:mm').format(
                'YYYY-MM-DD'
              )}
            </div>
          </div>

          <div className="pt-10 pb-[120px] border-b border-primary text-[18px] leading-[1.7em]">
            <EditorHtml str={notice.data.detail.KO.replace(/\n/g, '<br />')} />
          </div>

          <div
            className="flex items-center pt-5 space-x-1 font-bold cursor-pointer color-primary"
            onClick={() => navigate(`/notices/${path}`)}>
            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8 1.93945L8 11.9395L2 6.93945L8 1.93945Z"
                fill="#3B6161"
              />
            </svg>
            <span>목록으로 돌아가기</span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default NoticesItem
