import { useState } from 'react'
import { useDispatch } from 'react-redux'
import Logo from './Logo'

// 관련 사이트
const relatedSites = [
  {
    title: '대덕구청',
    link: 'https://www.daedeok.go.kr',
  },
  {
    title: '대덕문화원',
    link: 'http://www.ddcc.or.kr/index2.php',
  },
  {
    title: '대덕구 유튜브',
    link: 'https://www.youtube.com/channel/UCT1FXohZnfmvP5Ewk8FJYTQ',
  },
  {
    title: '대덕문화원 유투브',
    link: 'https://www.youtube.com/channel/UCOTZcWQUDR0p2n4j_tGD7vg',
  },
  {
    title: '대전광역시 아카이브',
    link: 'https://www.daejeon.go.kr/history/index.do',
  },
  {
    title: '대전찰칵',
    link: 'https://photo.daejeon.go.kr/',
  },
]

const Footer = () => {
  const dispatch = useDispatch()

  // State
  const [isSitesOpen, setIsSitesOpen] = useState(false)

  return (
    <footer
      id="footer"
      className="pt-5 pb-[80px] text-sm bg-primary color-white h-fit">
      <div className="container px-5 mx-auto">
        <div className="grid gap-5 lg:grid-cols-5">
          <div>
            <Logo />
          </div>
          <div>
            <p>
              대덕문화원
              <br />
              34360
              <br />
              대전광역시 대덕구 대전로 1348
            </p>
          </div>
          <div>
            <p>
              TEL. 042-627-7517
              <br />
              FAX. 042-621-7517
              <br />
              <a href="mailto:ddcc7517@hanmail.net">ddcc7517@hanmail.net</a>
            </p>
          </div>
          <div>
            <p>
              Copyright ⓒ {new Date().getFullYear()} DAEDEOK-GU, DAEDEOK
              CULTURAL CENTER All rights reserved
            </p>
          </div>
          <div className="relative">
            <div
              className="pl-2 pr-4 py-1.5 bg-[#F0F0F0] border border-primary text-black flex justify-between items-center cursor-pointer"
              onClick={() => setIsSitesOpen(true)}>
              <div>관련사이트</div>
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M10 6L0 6L5 -2.18557e-07L10 6Z" fill="black" />
              </svg>
            </div>
            {isSitesOpen && (
              <>
                <div
                  className="fixed top-0 left-0 z-50 w-full h-screen"
                  onClick={() => setIsSitesOpen(false)}></div>
              </>
            )}
            <div
              className={`absolute top-0 -translate-y-full -mt-1 w-full border border-primary bg-[#F0F0F0] py-2 z-50 transition-all ${
                isSitesOpen ? 'opaicty-100 visible' : 'opacity-0 invisible'
              }`}
              onClick={() => setIsSitesOpen(false)}>
              <ul>
                {relatedSites.map((site, index) => (
                  <li key={index}>
                    <a
                      href={site.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="px-2 leading-7 text-black">
                      {site.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
