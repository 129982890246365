/* eslint-disable react/display-name */
import usePeople from '@/hooks/usePeople'
import { ConvertedRecordItem } from '@/types/record'
import clsx from 'clsx'
import { ReactNode } from 'react'
import DateFormat from '../DateFormat'

type Props = {
  item: ConvertedRecordItem
}

function RecordsInfoSummary({ item }: Props) {
  const { data: intervieweeData } = usePeople([item.interviewee])

  if (!item || !intervieweeData) return <></>

  const interviewee = intervieweeData[0]

  return (
    <div className="p-4 md:p-5">
      <div
        className={clsx(
          'h-full',
          'space-y-2 5',
          'leading-[19.6px] text-[14px]'
        )}>
        {item.summary_record &&
          typeof item.summary_record === 'string' &&
          item.summary_record.length > 0 && (
            <div className="flex">
              <RecordsInfoSummary.Label>구술녹취 개요</RecordsInfoSummary.Label>

              <div className="pl-3">
                <p
                  className="mb-0 text-sm color-black font-[14px] font-normal"
                  dangerouslySetInnerHTML={{
                    __html: item.summary_record.replace(/\n/g, '<br />'),
                  }}></p>
              </div>
            </div>
          )}

        {item.location_address && typeof item.location_address === 'string' && (
          <div className="flex">
            <RecordsInfoSummary.Label>장소</RecordsInfoSummary.Label>
            <div className="pl-3">
              <p
                className="mb-0 text-sm color-black"
                dangerouslySetInnerHTML={{
                  __html: item.location_address.replace(/\n/g, '<br />'),
                }}></p>
            </div>
          </div>
        )}

        {item.create_date_start &&
          typeof item.create_date_start === 'string' && (
            <div className="flex">
              <RecordsInfoSummary.Label>자료생산일자</RecordsInfoSummary.Label>
              <div className="pl-3">
                <p className="mb-0 text-sm color-black font-noto-sans-mono">
                  <DateFormat str={item.create_date_start} />
                </p>
              </div>
            </div>
          )}

        {item.interviewer && typeof item.interviewer === 'string' && (
          <div className="flex">
            <RecordsInfoSummary.Label>면담자</RecordsInfoSummary.Label>
            <div className="pl-3">
              <p
                className="mb-0 text-sm color-black"
                dangerouslySetInnerHTML={{
                  __html: item.interviewer,
                }}></p>
            </div>
          </div>
        )}

        {item.interviewer && typeof item.interviewer === 'string' && (
          <div className="flex">
            <RecordsInfoSummary.Label>검독자</RecordsInfoSummary.Label>
            <div className="pl-3">
              <p
                className="mb-0 text-sm color-black"
                dangerouslySetInnerHTML={{
                  __html: item.interviewer,
                }}></p>
            </div>
          </div>
        )}

        {item.count && typeof item.count === 'string' && (
          <div className="flex">
            <RecordsInfoSummary.Label>면담횟수</RecordsInfoSummary.Label>
            <div className="pl-3">
              <p className="mb-0 text-sm color-black">{item.count}회</p>
            </div>
          </div>
        )}

        {item.duration && typeof item.duration === 'string' && (
          <div className="flex">
            <RecordsInfoSummary.Label>면담시간</RecordsInfoSummary.Label>
            <div className="pl-3">
              <p className="mb-0 text-sm color-black font-noto-sans-mono">
                {item.duration}
              </p>
            </div>
          </div>
        )}

        <div className="flex">
          <RecordsInfoSummary.Label>면담일자</RecordsInfoSummary.Label>
          <div className="pl-3">
            {item.interview_prev_date &&
            typeof item.interview_prev_date === 'string' ? (
              <p className="mb-0 text-sm color-black font-noto-sans-mono">
                <DateFormat str={item.interview_prev_date} /> (사전면담)
              </p>
            ) : (
              <></>
            )}
            {item.interview_date_1st &&
            typeof item.interview_date_1st === 'string' ? (
              <p className="mb-0 text-sm color-black font-noto-sans-mono">
                <DateFormat str={item.interview_date_1st} /> (1차)
              </p>
            ) : (
              <></>
            )}
            {item.interview_date_2nd &&
            typeof item.interview_date_2nd === 'string' ? (
              <p className="mb-0 text-sm color-black font-noto-sans-mono">
                <DateFormat str={item.interview_date_2nd} /> (2차)
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
        {item.memo && typeof item.memo === 'string' && (
          <div className="flex">
            <RecordsInfoSummary.Label>특기사항</RecordsInfoSummary.Label>
            <div className="pl-3">
              <p className="mb-0 text-sm color-black font-noto-sans-mono">
                {item.memo}
              </p>
            </div>
          </div>
        )}

        {interviewee &&
          interviewee.memo &&
          typeof interviewee.memo === 'string' && (
            <div className="flex">
              <RecordsInfoSummary.Label>
                면담자 기록사항
              </RecordsInfoSummary.Label>
              <div className="pl-3">
                <p className="mb-0 text-sm color-black font-noto-sans-mono">
                  {interviewee.memo}
                </p>
              </div>
            </div>
          )}
      </div>
    </div>
  )
}

RecordsInfoSummary.Label = ({ children }: { children: ReactNode }) => {
  return <div className="flex-none font-bold w-28">{children}</div>
}

export default RecordsInfoSummary
