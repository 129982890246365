import React, { useEffect } from 'react'
import Layout from '@/components/Layout'
import { modelNoticesActivitiesDevKey } from '@/configs/contents'
import NoticesItem from '@/components/notices/NoticesItem'

const NoticesActivitiesView = () => {
  return (
    <Layout full>
      <NoticesItem
        title="대덕마을기록단 활동"
        path="activities"
        devKey={modelNoticesActivitiesDevKey}
      />
    </Layout>
  )
}

export default NoticesActivitiesView
