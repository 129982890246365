import { pipe } from '@fxts/core'
import { useQuery } from 'react-query'

import { ConvertedPeopleItem, PeopleItem } from '@/types/people'
import { MEMEX_URLS, createMemexFetcher } from '@/utils/memexFetcher'

const usePeople = (peopleNames?: Array<string>) => {
  return useQuery({
    queryKey: ['people'],
    queryFn: async () => {
      const memexFetcher = createMemexFetcher()

      return await memexFetcher.post(MEMEX_URLS.GET_LISTS.PEOPLE, {
        size: 500,
        page: 0,
      })
    },
    select(data) {
      const converted = pipe(
        data,
        (data) => data.data.list,
        (list) =>
          list.map(({ uid, data }) => ({
            uid,
            ...data,
          })),
        (list) => list.map((item) => convertPeopleItem(item))
      )

      if (peopleNames) {
        return converted.filter((peopleItem) => {
          return Boolean(
            peopleNames.find((name) => peopleItem.name_search === name)
          )
        }) as Array<ConvertedPeopleItem>
      }

      return converted as Array<ConvertedPeopleItem>
    },
    refetchOnWindowFocus: false,
  })
}

const convertPeopleItem = (peopleItem: PeopleItem) => {
  return Object.entries(peopleItem).reduce((acc, [key, value]) => {
    acc[key] = value

    if (value && value['KO'] !== undefined) {
      acc[key] = value['KO']
    }

    // Category, Relation
    if (value instanceof Array && value[0]?.languageMap) {
      acc[key] = value[0].languageMap.KO
    }

    return acc
  }, {})
}

export default usePeople
