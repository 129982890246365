import { ReactNode } from 'react'

const SubHeader = ({ children }: { children: ReactNode }) => {
  return (
    <h2 className="font-serif text-[32px] font-medium tracking-[-2%] color-primary">
      {children}
    </h2>
  )
}

export default SubHeader
