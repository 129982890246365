import clsx from 'clsx'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router'

import IconArrowRight from '@/assets/images/icon-arrow-right-black.svg'
import Layout from '@/components/Layout'
import Loading from '@/components/Loading'
import useProjects from '@/hooks/useProjects'

const Projects = () => {
  const navigate = useNavigate()

  const { data: projects } = useProjects()

  if (!projects) return <Loading />

  return (
    <Layout full>
      <div
        className={clsx(
          'border-b-[1px] border-primary flex items-center pl-[20px]',
          'py-[12px] px-[20px]',
          'text-[24px] leading-[33.6px] font-bold color-primary'
        )}>
        대덕구 사람들
      </div>

      <div>
        {projects.map((project) => {
          return (
            <Projects.ProjectContainer
              key={project.uid}
              onClick={() => {
                navigate(`/records/${project.uid}`)
              }}>
              <div
                className={clsx(
                  'w-[620px] maxmd:w-fit h-[320px] flex-1 flex flex-col justify-between items-start py-[45px] px-[40px]'
                )}>
                <h1 className="w-[60%] maxmd:w-[80%] break-keep text-[28px] leading-[39.2px] font-bold maxmd:text-[20px] maxmd:leading-[28px]">
                  {project.project_title}
                </h1>

                <p className="mt-[20px] text-[16px] leading-[24px] maxmd:text-[14px] break-all">
                  {project.project_intro}
                </p>

                <span className="flex items-center text-[16px] leading-[22.4px] font-bold cursor-pointer maxmd:mt-[40px]">
                  이야기 보러가기
                  <img
                    className="w-[12px] h-[12px] ml-[4px]"
                    src={IconArrowRight}
                  />
                </span>
              </div>

              <div className="flex-1">
                <img
                  className="w-[620px] h-[320px]"
                  src={project.image[0].path}
                />
              </div>
            </Projects.ProjectContainer>
          )
        })}
      </div>
    </Layout>
  )
}

Projects.ProjectContainer = ({
  children,
  onClick = () => {},
}: {
  children: ReactNode
  onClick?: () => void
}) => {
  return (
    <div
      className={clsx(
        'w-[1240px] mx-[auto] bg-light-gray mt-[40px] [&:last-child]:mb-[40px] flex cursor-pointer',
        'maxmd:w-[calc(100vw-40px)] mx-auto',
        'maxmd:flex-col'
      )}
      onClick={onClick}>
      {children}
    </div>
  )
}

export default Projects
