import Layout from '@/components/Layout'
import NoticesList from '@/components/notices/NoticesList'

const NoticesArchives = () => {
  return (
    <Layout full mainClass="flex flex-col">
      <NoticesList title="대덕문화원 아카이브 사업" noticeType="archives" />
    </Layout>
  )
}

export default NoticesArchives
