import { pipe, sortBy } from '@fxts/core'
import { useQuery } from 'react-query'

import { ConvertedRecordItem, RecordsDataInterface } from '@/types/record'
import { 대전광역시_제거하기 } from '@/utils'
import { MEMEX_URLS, createMemexFetcher } from '@/utils/memexFetcher'

const useRecordList = ({
  recordItemUid,
  searchKeyword,
}: {
  recordItemUid?: string
  searchKeyword?: string
}) => {
  return useQuery({
    queryKey: ['record-list', recordItemUid],
    queryFn: async () => {
      const memexFetcher = createMemexFetcher()

      const recordList = await memexFetcher.post(MEMEX_URLS.GET_LISTS.RECORDS, {
        size: 200,
        page: 0,
      })

      return recordList
    },
    select(data) {
      const converted = pipe(
        data,
        ({ data }) => data.list,
        (list) => sortBy(({ order }) => order, list),
        (sortedList) =>
          sortedList.map(({ uid, data }) => ({
            uid,
            ...data,
          })),
        (list) => list.map((item) => convertRecordListItem(item))
      ) as Array<ConvertedRecordItem>

      if (recordItemUid) {
        return [
          (converted as Array<ConvertedRecordItem>).find(
            (item) => item.uid === recordItemUid
          ),
        ]
      }

      if (searchKeyword) {
        return converted.filter((item) =>
          item.interviewee.includes(searchKeyword)
        )
      }

      // Sort by .seq
      converted.sort((a, b) => {
        return a.seq - b.seq
      })

      return converted as Array<ConvertedRecordItem>
    },
    refetchOnWindowFocus: false,
  })
}

const convertRecordListItem = (recordListItem: RecordsDataInterface) => {
  return Object.entries(recordListItem).reduce((acc, [key, value]) => {
    acc[key] = value

    // string
    if (value && value['KO'] !== undefined) {
      acc[key] = value['KO']
    }

    if (key === 'feature_image') {
      acc[key] = {
        path: value[0]?.file.path ?? '',
        thumbnails: (value[0]?.file.thumbnails ?? []).map(
          (thumbnail) => thumbnail.path
        ),
      }

      return acc
    }

    if (key === 'interviewee') {
      acc[key] = value[0].languageMap.KO
      acc['interviewee_display'] = value[0].languageMap.KO.replace(/[0-9]/g, '')

      return acc
    }

    if (key === 'interviewer') {
      acc[key] = value[0].languageMap.KO

      return acc
    }

    if (key === 'location_address') {
      acc[key] = 대전광역시_제거하기(value['KO'])

      return acc
    }

    // Category, Relation
    if (value instanceof Array && value[0]?.languageMap) {
      const newValue = value.map((item) => {
        const newItem = {
          value: item.languageMap.KO,
          ...item,
        }

        delete newItem.languageMap

        return newItem
      })

      acc[key] = newValue
    }

    return acc
  }, {})
}

export default useRecordList
