import { useQuery } from 'react-query'

import { ConvertedMainItem, MainItem } from '@/types/main'
import { MEMEX_URLS, createMemexFetcher } from '@/utils/memexFetcher'
import { pipe } from '@fxts/core'

const useMain = () => {
  return useQuery({
    queryKey: ['main'],
    queryFn: async () => {
      const memexFetcher = createMemexFetcher()

      return await memexFetcher.post(MEMEX_URLS.GET_LISTS.MAIN, {
        size: 100,
        page: 0,
      })
    },
    select(data) {
      const converted = pipe(
        data,
        (data) => data.data.list,
        (list) => list[0],
        ({ uid, data }) => ({
          uid,
          ...data,
        }),
        (item) => convertMainItem(item)
      )

      return converted as ConvertedMainItem
    },
    refetchOnWindowFocus: false,
  })
}

const convertMainItem = (item: MainItem) => {
  return Object.entries(item).reduce((acc, [key, value]) => {
    acc[key] = value

    if (key === 'slideshow') {
      acc[key] = value.map((slide) => {
        return {
          path: slide.file.path,
          thumbnailList: slide.file.thumbnailList.map(({ path }) => path),
        }
      })
    }

    if (key === 'story_content') {
      acc[key] = value.map((el) => {
        return el.languageMap['KO']
      })
    }

    if (key === 'keywords') {
      acc[key] = value.map((el) => {
        return el.languageMap['KO']
      })
    }

    return acc
  }, {} as ConvertedMainItem)
}

export default useMain
