import { useEffect } from 'react'

import Layout from '@/components/Layout'
import MainArchives from '@/components/main/MainArchives'
import MainRecords from '@/components/main/MainRecords'
import MainSlider from '@/components/main/MainSlider'
import MainStatus from '@/components/main/MainStatus'

const Home = () => {
  useEffect(() => {
    document.body.scrollTop = 0
  }, [])

  return (
    <Layout full overflow="auto">
      <div>
        <div className="mb-20">
          <MainSlider />
        </div>
        <div className="mb-[180px] maxmd:mb-[60px]">
          <MainArchives />
        </div>
        <div className="mb-[180px] maxmd:mb-[60px]">
          <MainRecords />
        </div>
        <div className="pb-20 bg-light-gray maxmd:!bg-white">
          <MainStatus />
        </div>

        <div className="grid bg-light-gray place-items-center pb-[80px]">
          <button
            className="border-[1px] border-primary w-[95px] h-[46px] grid place-items-center font-bold color-primary"
            onClick={() => {
              document.body.scrollTo({ top: 0, behavior: 'smooth' })
            }}>
            위로 이동
          </button>
        </div>
      </div>
    </Layout>
  )
}

export default Home
