export const DONATION_FIELD_NAMES = {
  name: '기증자 이름',
  email: '이메일',
  phone: '연락처',
  address: '주소',
  archiveName: '자료 제목',
  archiveType: '자료 형태',
  origin: '생산자',
  productionDate: '생산일자',
  collector: '수집자',
  collectedAt: '수집일자',
  description: '세부내용',
  attachedFile: '첨부파일',
  agreement: '개인정보 수집 및 이용 동의',
}
