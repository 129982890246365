import { Filter } from '@/components/archives/ArchivesNav'
import { FilterKey } from '@/hooks/useArchives'
import { pluckKO } from '@/utils/convert'
import { MEMEX_URLS, createMemexFetcher } from '@/utils/memexFetcher'
import { pipe, toArray } from '@fxts/core'
import { useQuery } from 'react-query'

const useModelCategory = (
  modelName: string,
  categoryKey?: FilterKey | 'data_format'
) => {
  return useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      const memexFetcher = await createMemexFetcher()
      return await memexFetcher.get(MEMEX_URLS.GET_CATEGORIES[modelName])
    },
    select: (data) => {
      if (!categoryKey) {
        const converted = pipe(
          data,
          (data) => data.data.list,
          (list) =>
            list.reduce((acc, { devKey, categories }) => {
              acc[devKey] = convertCategories(categories, devKey)

              return acc
            }, {})
        )

        return converted
      }

      return pipe(
        data,
        (data) => data.data.list,
        (list) =>
          list.find((category) => category.devKey === categoryKey)
            ?.categories ?? [],
        (categories) => convertCategories(categories, categoryKey as FilterKey),
        toArray
      )
    },
    refetchOnWindowFocus: false,
  })
}

const convertCategories = (
  categories: Array<
    Record<string, unknown> & { id: number; languageMap: { KO: string } }
  >,
  categoryKey: FilterKey
): Array<Filter> => {
  return categories.map((category) => ({
    categoryId: category.id,
    categoryKey,
    name: pluckKO(category),
  }))
}

export default useModelCategory
