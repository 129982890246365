import clsx from 'clsx'

import useIsMobile from '@/hooks/useIsMobile'
import { ConvertedImageItem } from '@/types/images'

/**
 * 콘텐츠 이미지 뷰어 & 다운로드 창
 * @param param0
 * @returns
 */
export const RecordsImageViewer = ({
  viewer,
  active,
  images,
  onSetActive,
  onSetViewer,
}: {
  viewer: boolean
  active: number
  images: Array<ConvertedImageItem>
  onSetActive: Function
  onSetViewer: Function
}) => {
  const isMobile = useIsMobile()

  /**
   * 이미지 인덱스 수정
   * @param idx
   */
  const onHandleChangeActive = (idx) => {
    let targetIdx = idx
    if (targetIdx < 0) {
      targetIdx = images.length - 1
    } else if (idx >= images.length) {
      targetIdx = 0
    }

    onSetActive(targetIdx)
  }

  /**
   * 이미지 뷰어 닫기
   * @param idx
   */
  const closeViewer = () => {
    onSetViewer(false)
  }

  const activeImage = images[active]

  if (!activeImage) return <></>

  return (
    <div
      className={clsx(
        `image-viewer`,
        !viewer && 'hidden',
        // below are for mobile responsive layout
        'maxmd:!h-[100dvh] maxmd:bottom-[inherit] maxmd:top-0',
        ''
      )}>
      <div className="flex items-center image-viewer-nav maxmd:w-full">
        <div className="flex items-center justify-between w-full">
          {/* 헤더 */}
          <div className="flex flex-1 image-viewer-name maxmd:items-center maxmd:justify-between">
            <div className="name">{activeImage.name}</div>

            {isMobile && (
              <button
                className="ml-auto btn btn-close"
                type="button"
                onClick={() => closeViewer()}></button>
            )}
          </div>

          {/* 버튼들 */}
          <div className="image-viewer-tools">
            <div className="flex items-center justify-around w-full space-x-4">
              {isMobile && (
                <button
                  type="button"
                  onClick={() => onHandleChangeActive(active - 1)}
                  className="btn btn-image-nav left"></button>
              )}

              <div className="w-28 maxmd:hidden md:w-0"></div>

              <div>
                {active + 1}/{images.length}
              </div>

              {!isMobile && (
                <div className="flex items-center space-x-4">
                  <button
                    type="button"
                    onClick={() => onHandleChangeActive(active - 1)}
                    className="btn btn-image-nav left"></button>

                  <button
                    type="button"
                    onClick={() => onHandleChangeActive(active + 1)}
                    className="btn btn-image-nav right"></button>

                  <button
                    type="button"
                    onClick={() => closeViewer()}
                    className="btn btn-close"></button>
                </div>
              )}

              {isMobile && (
                <button
                  type="button"
                  onClick={() => onHandleChangeActive(active + 1)}
                  className="btn btn-image-nav right"></button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* 이미지들 */}
      <div className="image-viewer-content">
        <div
          className="image-container"
          style={{
            backgroundImage: `url(${
              activeImage.files[0]?.path ??
              activeImage.files[0]?.thumbnailList[0] ??
              ''
            })`,
          }}></div>
      </div>
    </div>
  )
}
