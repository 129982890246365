import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import Footer from './Footer'
import HeaderLogo from './HeaderLogo'
import HeaderNav from './HeaderNav'

const Header = () => {
  const navigate = useNavigate()

  // State
  const [nav, setNav] = useState<boolean>(false)
  const [scroll, setScroll] = useState<boolean>(false)

  // Effect
  useEffect(() => {
    window.addEventListener('scroll', setHeaderLayout)
  }, [])

  /**
   * 헤더 레이아웃 변경
   * @returns
   */
  const setHeaderLayout = () => {
    const _header = document.getElementById('header')

    if (!_header) return

    _header.classList.add(window.scrollY ? 'header-default' : 'header-lg')
    _header.classList.remove(!window.scrollY ? 'header-default' : 'header-lg')

    setScroll(window.scrollY > 0)
  }

  return (
    <header
      id="header"
      className={clsx(
        `header-lg px-3 lg:px-5 flex items-center justify-between bg-white z-50`,
        nav && 'show-nav'
      )}>
      <div className="cursor-pointer" onClick={() => navigate('/')}>
        <HeaderLogo lg={!scroll} />
      </div>

      <nav id="header-nav" className="color-primary">
        <HeaderNav nav={nav} />
        <button
          type="button"
          className="absolute z-10 top-4 right-4 btn btn-close btn-close-white md:hidden"
          onClick={() => setNav(false)}>
          닫기
        </button>

        <div className="absolute bottom-0 left-0 md:hidden">
          <Footer />
        </div>
      </nav>

      <button
        type="button"
        className="btn btn-ham md:hidden"
        onClick={() => setNav(true)}
      />
    </header>
  )
}

export default Header
