import clsx from 'clsx'

import CheckedIcon from '@/assets/images/checked.svg'

const Checkbox = ({
  isSelected,
  className,
}: {
  isSelected: boolean
  className?: string
}) => {
  return (
    <div
      className={clsx(
        'w-[16px] h-[16px] border-[1px] border-primary',
        'grid place-items-center',
        'select-none',
        isSelected && 'bg-white',
        className
      )}>
      {isSelected && <img className="w-[12px] h-[12px]" src={CheckedIcon} />}
    </div>
  )
}

export default Checkbox
