import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import Projects from '@/components/Projects'
import ScrollToTop from '@/components/ScrollToTop'
import About from '@/pages/About'
import ArchivesViewByMap from '@/pages/ArchivesVIewByMap'
import ArchivesList from '@/pages/ArchivesViewByList'
import Home from '@/pages/Home'
import { store } from '@/states/store'
import Donates from './pages/Donates'
import NotFound from './pages/NotFound'
import NoticesActivities from './pages/NoticesActivities'
import NoticesActivitiesView from './pages/NoticesActivitiesView'
import NoticesArchives from './pages/NoticesArchives'
import NoticesArchivesView from './pages/NoticesArchivesView'
import Records from './pages/Records'

if (process.env.REACT_APP_GOOGLE_TAG_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_TAG_ID as string)
}

const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight()

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <Provider store={store}>
          <Helmet>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link
              rel="preconnect"
              href="https://fonts.gstatic.com"
              crossOrigin=""
            />
            <link
              href="https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;600;700&family=Noto+Sans+Mono:wght@400;500;600&display=swap"
              rel="stylesheet"
            />
          </Helmet>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route index path="/" element={<Home />} />
              <Route index path="/about" element={<About />} />
              <Route
                index
                path="/archives/map"
                element={<ArchivesViewByMap />}
              />
              <Route index path="/archives/list" element={<ArchivesList />} />
              <Route index path="/projects" element={<Projects />} />
              <Route index path="/records/:surveyId" element={<Records />} />
              <Route index path="/donates" element={<Donates />} />
              <Route
                index
                path="/notices/archives"
                element={<NoticesArchives />}
              />
              <Route
                index
                path="/notices/archives/:id"
                element={<NoticesArchivesView />}
              />
              <Route
                index
                path="/notices/activities"
                element={<NoticesActivities />}
              />
              <Route
                index
                path="/notices/archives/:id"
                element={<NoticesArchivesView />}
              />
              <Route
                index
                path="/notices/activities/:id"
                element={<NoticesActivitiesView />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </Provider>
      </RecoilRoot>
    </QueryClientProvider>
  )
}

export default App
