import { createMemexFetcher, MEMEX_URLS } from '@/utils/memexFetcher'
import { pipe } from '@fxts/core'
import { compareDesc } from 'date-fns'
import { useQuery } from 'react-query'

const useNotice = <ListType, ConvertedListType>(
  noticeType: 'archives' | 'activities',
  pageNumber: number
) => {
  const queryKey = ['notices', noticeType, pageNumber]

  const fetchNoticeList = async () => {
    const memexFetcher = createMemexFetcher()

    return await memexFetcher.post(MEMEX_URLS.GET_NOTICES[noticeType], {
      page: pageNumber,
      size: 12,
    })
  }

  return useQuery(queryKey, fetchNoticeList, {
    select: (data) => {
      const converted = pipe(
        data,
        (data) => data.data,
        ({ list, pageInfo }) => {
          return {
            list: pipe(
              list,
              (list) => list.map(({ uid, data }) => ({ uid, ...data })),
              (list) =>
                list.map((item) =>
                  convertNoticeItem<ListType, ConvertedListType>(item)
                ),
              (list) => list.filter((item) => item.created_date),
              (list) =>
                list.sort((a, b) => compareDesc(a.created_date, b.created_date))
            ),
            pageInfo,
          }
        }
      )

      return converted as {
        list: Array<ConvertedListType>
        pageInfo: {
          page: number
          size: number
          isLast: boolean
          totalPages: number
          totalElements: number
        }
      }
    },
    refetchOnWindowFocus: false,
  })
}

const convertNoticeItem = <ListType, ConvertedListType>(item: ListType) => {
  return Object.entries(item).reduce((acc, [key, value]) => {
    acc[key] = value

    if (value && value['KO'] !== undefined) {
      acc[key] = value['KO']
    }

    return acc
  }, {}) as ConvertedListType
}

export default useNotice
