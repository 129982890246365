import useTopbanner from '@/hooks/useTopbanner'

function MainTopbanner() {
  const { data: topbanner } = useTopbanner()

  return (
    topbanner &&
    topbanner.top_banner && (
      <div
        id="top-banner"
        className="pt-5 pb-5 text-center bg-primary color-white lg:pt-4">
        <div className="text-2xl lg:text-[32px] leading-10 lg:leading-[45px] font-bold">
          {topbanner.top_title}
        </div>
        <div className="text-lg leading-[30px] hidden lg:block">
          {topbanner.top_content}
        </div>
      </div>
    )
  )
}

export default MainTopbanner
