import clsx from 'clsx'
import { ReactNode } from 'react'

import useModelCategory from '@/hooks/useModelCategory'
import useArchives from '@/hooks/useArchives'
import { splitArray } from '@/utils'
import { useSetRecoilState } from 'recoil'
import { selectedFiltersState } from '@/components/archives/ArchivesNav'
import { useNavigate } from 'react-router'
import useIsMobile from '@/hooks/useIsMobile'

const MainStatus = () => {
  const isMobile = useIsMobile()

  const { data: locationCategories } = useModelCategory(
    'archives',
    'location_category'
  )

  const { data: archives } = useArchives({})

  if (!archives || !locationCategories) return <></>

  const splitted = isMobile
    ? splitArray(locationCategories, 6)
    : splitArray(locationCategories, 3)

  return (
    <div className="pt-10 pb-20 px-5 lg:px-[100px]">
      <h2 className="mb-3 text-3xl font-bold color-primary maxmd:text-[24px]">
        보유기록물 현황
      </h2>

      <p className="mb-10 text-xl font-bold color-primary maxmd:text-[18px] maxmd:leading-[26.64px]">
        대덕 도시문화역사 아카이브는 대덕구와 관련된 기록을 기다립니다
      </p>

      <div className="flex">
        {splitted.map((el, index) => {
          return (
            <MainStatus.RecordColumn key={`record-column-${index}`}>
              {el.map((el) => {
                const count = archives.filter(
                  (archive) => archive.location_category === el.name
                ).length

                return (
                  <MainStatus.Record
                    key={el.categoryId}
                    categoryId={el.categoryId}
                    label={el.name}
                    value={String(count)}
                  />
                )
              })}
            </MainStatus.RecordColumn>
          )
        })}
      </div>
    </div>
  )
}

MainStatus.Record = ({
  categoryId,
  label,
  value,
}: {
  categoryId: number
  label: string
  value: string
}) => {
  const navigate = useNavigate()
  const setSelectedFilter = useSetRecoilState(selectedFiltersState)

  return (
    <div
      className={clsx(
        'flex items-center justify-between py-4',
        'border-t-[1px] [&:last-child]:border-b-[1px] border-primary',
        'text-[18px] leading-[25.2px] font-semibold',
        'cursor-pointer'
      )}
      onClick={() => {
        setSelectedFilter((prev) => [
          ...prev,
          {
            categoryId,
            name: label,
            categoryKey: 'location_category',
          },
        ])

        navigate('/archives/list')
      }}>
      <div>{label}</div>
      <div>{value}</div>
    </div>
  )
}

MainStatus.RecordColumn = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex flex-col flex-1 mr-[20px] [&:last-child]:mr-[0px]">
      {children}
    </div>
  )
}

export default MainStatus
