import React from 'react'
import moment from 'moment'

function DateFormat({ str }) {
  return (
    <>
      {moment(str, 'YYYYMMDD').isValid()
        ? moment(str, 'YYYYMMDD').format('YYYY/MM/DD')
        : moment(str, 'YYYYMM').isValid()
        ? moment(str, 'YYYYMM').format('YYYY/MM')
        : moment(str, 'YYYY').isValid()
        ? moment(str, 'YYYY').format('YYYY')
        : str}
    </>
  )
}

export default DateFormat
