import Layout from '@/components/Layout'
import { setArchiveFilter } from '@/states/actions/contents.actions'
import { getAllFilters } from '@/states/reducers/contents.reducer'
import { useAppDispatch } from '@/states/store'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const About = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setArchiveFilter(getAllFilters(), ''))
  }, [])

  /**
   * 대덕 도시역사문화 아카이브 소개 테이블
   * @returns
   */
  const AboutTable = () => {
    return (
      <table className="w-[950px] md:w-full">
        <colgroup>
          <col style={{ width: '60px' }} />
          <col style={{ width: '30.7%' }} />
          <col style={{ width: '30.7%' }} />
          <col style={{ width: '30.7%' }} />
        </colgroup>
        <tbody>
          <tr className="border-t border-b border-primary">
            <th className="py-4 text-center color-primary text-bold bg-light-gray">
              목표
            </th>
            <th className="text-center border-l color-primary text-bold bg-light-gray border-primary">
              기록물의 영속적 보관
            </th>
            <th className="text-center border-l color-primary text-bold bg-light-gray border-primary">
              기록문화 정착
            </th>
            <th className="text-center border-l color-primary text-bold bg-light-gray border-primary">
              기록물의 활용
            </th>
          </tr>
          <tr className="border-b border-primary">
            <th className="text-center color-primary text-bold bg-light-gray">
              방향
            </th>
            <td className="p-1.5 border-l border-primary align-top">
              <ul className="list-disc pl-7 break-keep">
                <li>체계적인 기록물 관리 시스템 구축</li>
                <li>대덕구 역사문화자원의 종합적 수집</li>
                <li>민·관 아카이브 거버넌스 구축</li>
              </ul>
            </td>
            <td className="p-1.5 border-l border-primary align-top">
              <ul className="list-disc pl-7 break-keep">
                <li>구민 주도 역사문화자원 수집 방안 마련</li>
                <li>일상의 기록화를 위한 교육체계 구축</li>
                <li>범구민 활용 통합 플랫폼 구축</li>
              </ul>
            </td>
            <td className="p-1.5 border-l border-primary align-top">
              <ul className="list-disc pl-7 break-keep">
                <li>수집 기록물의 가치 평가 및 제언</li>
                <li>문화예술적 활용 콘텐츠 개발</li>
                <li>수집 기록물의 이용자 접근성 확대</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  /**
   * 홈페이지 이용안내
   * @returns
   */
  const HomepageTable = () => {
    return (
      <table>
        <colgroup>
          <col className="hidden lg:table-column" style={{ width: '160px' }} />
          <col className="lg:hidden" style={{ width: '120px' }} />
          <col className="hidden" style={{ width: '160px' }} />
          <col />
        </colgroup>
        <tbody>
          <tr className="border-t border-b border-primary">
            <th className="text-center color-primary text-bold bg-light-gray">
              아카이브
            </th>
            <td className="p-1.5 border-l border-primary align-top break-keep">
              대덕구의 다양한 역사문화자원들과 관련된 사진, 영상, 음성 등
              기록물을 검색하고 열람할 수 있습니다.
            </td>
          </tr>
          <tr className="border-t border-b border-primary">
            <th className="text-center color-primary text-bold bg-light-gray">
              대덕구 사람들
            </th>
            <td className="p-1.5 border-l border-primary align-top break-keep">
              일상적이지만 특별한 대덕구 사람들의 이야기를 구술채록하여 모아둔
              공간입니다.
            </td>
          </tr>
          <tr className="border-t border-b border-primary">
            <th className="text-center color-primary text-bold bg-light-gray">
              기증
            </th>
            <td className="p-1.5 border-l border-primary align-top break-keep">
              여러분이 가지고 있는 대덕구 지역의 다양한 자료를 기증해주세요.
              당신의 자료가 역사가 됩니다.
            </td>
          </tr>
          <tr className="border-t border-b border-primary">
            <th className="text-center color-primary text-bold bg-light-gray">
              소식
            </th>
            <td className="p-1.5 border-l border-primary align-top">
              대덕문화원이 운영하는 기록과 관련된 사업 소식과 소중한 의견들을
              제시할 수 있는 공간입니다.
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  /**
   * 구글 지도
   * @returns
   */
  const GoogleMap = () => {
    return (
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3212.4655140994737!2d127.418137376579!3d36.3737232723718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x356549b5fbc82149%3A0xbbf7d27c17ce8a14!2z64yA642V66y47ZmU7JuQ!5e0!3m2!1sen!2skr!4v1705561087876!5m2!1sen!2skr"
        width="100%"
        height="200"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"></iframe>
    )
  }

  return (
    <Layout full>
      <div className="px-5 py-3 bordered-b">
        <h1 className="para-lg color-primary">소개</h1>
      </div>
      <div className="pt-10 pb-48 container mx-auto max-w-[820px] space-y-20 px-5 lg:px-0">
        <div className="space-y-10">
          <h2 className="mb-0 para-lg color-primary">
            대덕 도시역사문화 아카이브 소개
          </h2>
          <p className="mb-0 text-lg">
            대덕의 원래 이름은 덕을 품을 곳이라는 의미를 지닌 ‘회덕’으로 고려
            태조 때부터 현재까지 천년의 역사가 숨 쉬는 대전의 발원지이자
            뿌리입니다. 대전의 ‘대’와 회덕의 ‘덕’을 따서 대덕이라는 이름으로
            불리고 있지만 김정호의 대동여지도에 대전 일대가 회덕으로 표기
            되어있는 것으로 봤을 때, 회덕은 과거 천년동안 대전 지역의 정치,
            경제, 사회, 문화의 중심지로 역사와 함께 해왔습니다.
            <br />
            <br />
            하지만 현재 대덕구는 지역개발 등 다양한 목적에 의해 과거의 모습과
            이야기들은 빠르게 사라져가고 변화해가고 있습니다.
            <br />
            <br />
            이에 대덕구와 대덕문화원은 지역의 산재되어 있는 다양한
            역사문화자원을 체계적, 통합적으로 수집하고 기록하여 미래의 문화적
            자산으로 활용하고자 대덕 도시역사문화 아카이브를 운영하고 있습니다.
          </p>
          <div className="w-full overflow-x-auto md:overflow-hidden">
            <AboutTable />
          </div>
          <div className="flex">
            <a
              href="https://www.elis.go.kr/allalr/selectAlrBdtOne?alrNo=30230104216051&histNo=001&menuNm=main"
              className="flex items-center space-x-2 bg-light-gray border border-[#D7D7D7] p-3">
              <span>
                &lt; 대덕구 도시역사문화 아카이브 구축 및 운영 조례 &gt;
                바로가기
              </span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 3.00006H13V13.0001"
                  stroke="black"
                  strokeWidth="1.5"
                />
                <path
                  d="M13 3.00006L3 13.0001"
                  stroke="black"
                  strokeWidth="1.5"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="space-y-10">
          <h2 className="mb-0 para-lg color-primary">홈페이지 이용안내</h2>
          <HomepageTable />
        </div>
        <div className="space-y-10">
          <h2 className="mb-0 para-lg color-primary">대덕문화원 소개</h2>
          <p className="mb-0 text-lg">
            대덕 도시역사문화 아카이브와 관련하여 궁금하신 사항이나 제안해 주실
            사항이 있으시면 아래의 연락처로 문의 주시기 바랍니다.
          </p>
          <div>
            <div className="grid gap-5 lg:grid-cols-2">
              <div>
                <GoogleMap />
              </div>
              <div>
                <p className="mb-0 text-base">
                  TEL. 042-627-7517
                  <br />
                  FAX. 042-621-7517
                  <br />
                  MAIL.{' '}
                  <a href="mailto:ddcc7517@hanmail.net">ddcc7517@hanmail.net</a>
                  <br />
                  ADDRESS. 대전 대덕구 대전로 1348. 1층 사무국
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
