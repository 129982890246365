import { ConvertedImageItem } from '@/types/images'

export const pluckKO = (
  obj: Record<string, unknown> & { languageMap: { KO: string } }
) => {
  return obj.languageMap.KO
}

export const removeNumbers = (str: string) => {
  return str.replace(/\d+/g, '')
}

export const pickImageSource = (image: ConvertedImageItem) => {
  if (!image || !image.files) return ''

  const imageFile = image.files[0]
  if (!imageFile) return ''

  const largeThumbnail = imageFile.thumbnailList.find((thumbnailPath) =>
    thumbnailPath.includes('LARGE')
  )

  if (largeThumbnail) return largeThumbnail

  return imageFile.path
}

export const convertOrigin = (origin: string) => {
  return origin.split('\n').filter((str) => str)
}

/**
 * Get Youtube ID
 * @param url
 * @returns
 */
export const youtubeParser = (url: string) => {
  var regExp =
    /(?:youtube(?:-nocookie)?\.com\/(?:[^\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  var match = url.match(regExp)
  return match ? match[1] : false
}
