import { range } from '@fxts/core'
import clsx from 'clsx'
import { last } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { atom, useRecoilState, useRecoilValue } from 'recoil'

import Loading from '@/components/Loading'
import useNotice from '@/hooks/useNotice'
import { ConvertedNoticeArchives, NoticeArchives } from '@/types/notice'

import { ReactComponent as PageLeftIcon } from '@/assets/images/icon-arrow-page--left.svg'
import { ReactComponent as PageRightIcon } from '@/assets/images/icon-arrow-page--right.svg'

const currentPageState = atom({
  key: 'currentPageState',
  default: 0,
})

const currentPageSectionState = atom({
  key: 'currentPageSectionState',
  default: 0,
})

const NoticesList = ({
  title,
  noticeType,
}: {
  title: string
  noticeType: 'archives' | 'activities'
}) => {
  const navigate = useNavigate()
  const currentPage = useRecoilValue(currentPageState)

  const { data } = useNotice<NoticeArchives, ConvertedNoticeArchives>(
    noticeType,
    currentPage
  )

  if (!data) return <Loading hasLayout={false} />

  const { list, pageInfo } = data

  return (
    <>
      <div className="px-5 py-3 bordered-b">
        <h1 className="para-lg color-primary">{title}</h1>
      </div>

      <div className="flex flex-col flex-1 w-[820px] mx-auto">
        <div className="pt-10 pb-48 container mx-auto max-w-[820px] px-5 lg:px-0">
          <ul className="mb-10 lg:text-lg">
            {list.map((notice) => (
              <li
                key={notice.uid}
                className="py-3 border-b border-[#ADADAD] notice-item transition-colors duration-100"
                onClick={() =>
                  navigate(`/notices/${noticeType}/${notice.uid}`)
                }>
                <div className="flex justify-between space-x-1 pr-[1em]">
                  <div className="flex items-center">
                    <svg
                      className="notice-item-icon"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M4 11V1L10 6L4 11Z" fill="#3B6161" />
                    </svg>
                    <div>{notice.title}</div>
                  </div>

                  <div className="w-[100px] flex-none text-center">
                    {notice.created_date
                      ? moment(notice.created_date, 'YYYYMMDDThh:mm').format(
                          'YYYY-MM-DD'
                        )
                      : '-'}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <NoticesList.Pages totalPages={pageInfo.totalPages} />
      </div>
    </>
  )
}

const PAGES_PER_SECTION = 10

NoticesList.Pages = ({ totalPages }: { totalPages: number }) => {
  const [currentPage, setCurrentPage] = useRecoilState(currentPageState)
  const [pageSection, setPageSection] = useState<Array<Array<number>>>([])
  const [currentPageSection, setCurrentPageSection] = useRecoilState(
    currentPageSectionState
  )

  useEffect(() => {
    if (pageSection.length > 0) return

    const newPageSection = Array.from(
      range(0, totalPages, PAGES_PER_SECTION)
    ).map((start) => {
      const end =
        start + PAGES_PER_SECTION > totalPages
          ? totalPages
          : start + PAGES_PER_SECTION

      return Array.from(range(start, end))
    })

    setPageSection(newPageSection)
  }, [pageSection, PAGES_PER_SECTION])

  const currentPages = pageSection[currentPageSection]

  const handlePageNavigatorClick = (direction: 'prev' | 'next') => () => {
    if (direction === 'prev') {
      setCurrentPageSection((prev) => prev - 1)
      setCurrentPage(currentPages[0] - 1)
    }

    if (direction === 'next') {
      setCurrentPageSection((prev) => prev + 1)
      setCurrentPage(last(currentPages) + 1)
    }
  }

  const handlePageClick = (page: number) => () => {
    setCurrentPage(page)
  }

  const hasFirstPage = currentPages?.includes(0) ?? false
  const hasLastPage = currentPages?.includes(totalPages - 1) ?? false

  return (
    <div className="flex items-center justify-center ml-auto mt-auto mb-[24px]">
      {!hasFirstPage && (
        <PageLeftIcon
          className="ml-[4px] cursor-pointer"
          width={24}
          height={24}
          onClick={handlePageNavigatorClick('prev')}
        />
      )}

      <ul className="flex">
        {currentPages?.map((page) => {
          return (
            <li
              key={`pageNumber-${page}`}
              className={clsx(
                'px-[8px] pt-[4px] pb-[6px]',
                currentPage === page && 'border-[1px] border-primary',
                'cursor-pointer'
              )}
              onClick={handlePageClick(page)}>
              <span className="text-[16px] leading-[1.4em] color-primary">
                {page + 1}
              </span>
            </li>
          )
        })}
      </ul>

      {!hasLastPage && (
        <PageRightIcon
          className="mr-[4px] cursor-pointer"
          width={24}
          height={24}
          onClick={handlePageNavigatorClick('next')}
        />
      )}
    </div>
  )
}

export default NoticesList
