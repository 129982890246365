import { useQuery } from 'react-query'

import { ConvertedVideos, Videos } from '@/types/videos'
import { MEMEX_URLS, createMemexFetcher } from '@/utils/memexFetcher'
import { pipe } from '@fxts/core'

const useVideos = (videoNames?: Array<string>) => {
  return useQuery({
    queryKey: ['videos'],
    queryFn: async () => {
      const memexFetcher = createMemexFetcher()

      return await memexFetcher.post(MEMEX_URLS.GET_LISTS.VIDEOS, {
        size: 500,
        page: 0,
      })
    },
    select(data) {
      const converted = pipe(
        data,
        ({ data }) => data.list,
        (list) => list.map(({ uid, data }) => ({ uid, ...data })),
        (list) => list.map((item) => convertVideoProps(item))
      )

      if (videoNames) {
        return videoNames.map((videoName) => {
          return converted.find((video) => video.search_name === videoName)
        }) as unknown as Array<ConvertedVideos>
      }

      return converted as Array<ConvertedVideos>
    },
    refetchOnWindowFocus: false,
  })
}

const convertVideoProps = (video: Videos) => {
  return Object.entries(video).reduce((acc, [key, value]) => {
    acc[key] = value

    if (value && value['KO'] !== undefined) {
      acc[key] = value['KO']
    }

    if (value instanceof Array && value[0]?.languageMap) {
      acc[key] = value[0].languageMap.KO
    }

    return acc
  }, {}) as ConvertedVideos
}

export default useVideos
