import Layout from '@/components/Layout'

const Loading = ({ hasLayout = true }: { hasLayout?: boolean }) => {
  if (!hasLayout)
    return (
      <div className="grid place-items-center h-[calc(100svh-72px)]">
        페이지를 불러오는 중입니다..
      </div>
    )

  return (
    <Layout full>
      <div className="grid place-items-center h-[calc(100svh-72px)]">
        페이지를 불러오는 중입니다..
      </div>
    </Layout>
  )
}

export default Loading
