import { projectUid } from '@/configs/contents'

export const postFile = async (file: File) => {
  // 1. S3 presigned URL
  const presignedResult = await getPresignedURL(file.name)

  const { key: presignedKey, url, metadata } = presignedResult

  // 2. presigned URL에 업로드
  const hasPresignedUploaded = await uploadOnPresignedURL(file, url, metadata)

  if (!hasPresignedUploaded) {
    alert('파일 업로드 중 에러 발생..')
  }

  // 3. 미믹스 서버에 파일 정보 저장
  const memexUploadedResult = await saveFilesOnMemexServer(presignedKey)

  // 4. 프로젝트에 미디어 생성
  const createMediaResult = await createMediaOnProject(memexUploadedResult)

  console.log('생성 완료..', createMediaResult)

  return createMediaResult
}

type PresignedMetadata = Record<string, string>

interface PresingedRes {
  key: string
  metadata: PresignedMetadata
  url: string
}

interface MemexUploadedResult {
  fileId: number
  languageMap: null
  type: string
  value: string
}

const getPresignedURL = async (fileName: string) => {
  const url = `${process.env.REACT_APP_MEMEX_API}/projects/${projectUid}/files/access`

  const presingedRes = await fetch(url, {
    method: 'POST',
    body: fileName,
    headers: {
      'Access-Token': process.env.REACT_APP_MEMEX_APP_KEY ?? '',
    },
  })

  return (await presingedRes.json()) as PresingedRes
}

const uploadOnPresignedURL = async (
  file: File,
  url: string,
  metadata: PresignedMetadata
) => {
  const presignedUploadRes = await fetch(url, {
    method: 'PUT',
    body: file,
    headers: {
      ...metadata,
    },
  })

  return presignedUploadRes.ok
}

const saveFilesOnMemexServer = async (presignedKey: string) => {
  const url = `${process.env.REACT_APP_MEMEX_API}/projects/${projectUid}/files/upload`

  const memexServerRes = await fetch(url, {
    method: 'POST',
    headers: {
      'Access-Token': process.env.REACT_APP_MEMEX_APP_KEY ?? '',
    },
    body: presignedKey,
  })

  return (await memexServerRes.json()) as MemexUploadedResult
}

interface MediaCreationResult {
  id: number
  file: {
    id: number
    name: string
    path: string
  }
  languageMap: {
    KO: {
      name: string
    }
  }
  mediaType: string
  value: string
}

const createMediaOnProject = async (uploadedResult: MemexUploadedResult) => {
  const url = `${process.env.REACT_APP_MEMEX_API}/projects/${projectUid}/media`

  const body = JSON.stringify({
    ...uploadedResult,
    languageMap: {
      KO: {
        name: uploadedResult.value, // filename
        description: '',
      },
    },
  })

  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Access-Token': process.env.REACT_APP_MEMEX_APP_KEY ?? '',
      'Content-Type': 'application/json',
    },
    body,
  })

  return (await res.json()) as MediaCreationResult
}

interface CreateDonationInput {
  publish: boolean
  data: {
    donor: {
      KO: string
    }
    email: string
    contact: string
    address: string
    data_title: string
    data_format: Array<number> // 왜 array?
    producer: string
    production_date: string
    collector: string
    collect_date: string
    details: string
    agreement: boolean | string
    attachment: File | null
  }
}

export const createDonation = async (input: CreateDonationInput) => {
  let attachment = null

  // 첨부파일 존재 시
  if (input.data.attachment) {
    const { id: mediaId } = await postFile(input.data.attachment)

    attachment = [
      {
        id: mediaId,
        annotations: [],
      },
    ]
  }

  const url = `https://api.memexdata.io/memex/external/projects/${projectUid}/models/donate/contents`
  const body = JSON.stringify({
    ...input,
    data: {
      ...input.data,
      attachment,
    },
  })

  const postRes = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Token': process.env.REACT_APP_MEMEX_APP_KEY ?? '',
      'X-Forwarded-Host': location.hostname,
    },
    body,
  })

  console.log('생성 결과', postRes)

  if (!postRes.ok) {
    alert('기증내용 전송 중 문제가 발생하였습니다.')
  }
}
