import { useQuery } from 'react-query'

import { ConvertedTopbannerItem, TopbannerItem } from '@/types/topbanner'
import { MEMEX_URLS, createMemexFetcher } from '@/utils/memexFetcher'
import { pipe } from '@fxts/core'

const useTopbanner = () => {
  return useQuery({
    queryKey: ['topbanner'],
    queryFn: async () => {
      const memexFetcher = createMemexFetcher()

      return await memexFetcher.post(MEMEX_URLS.GET_LISTS.TOPBANNER, {
        size: 1,
        page: 0,
        searchConds: [
          {
            componentType: 'BOOLEAN',
            devKey: 'top_banner',
            condition: JSON.stringify({
              value: true,
            }),
          },
        ],
      })
    },
    select(data) {
      if (!data.data.list || data.data.list.length === 0) return null

      const converted = pipe(
        data,
        (data) => data.data.list,
        (list) => list[0],
        ({ uid, data }) => ({
          uid,
          ...data,
        }),
        (item) => convertTopbannerItem(item)
      )

      console.log(converted)

      return converted as ConvertedTopbannerItem
    },
    refetchOnWindowFocus: false,
  })
}

const convertTopbannerItem = (item: TopbannerItem) => {
  return Object.entries(item).reduce((acc, [key, value]) => {
    acc[key] = value

    return acc
  }, {} as ConvertedTopbannerItem)
}

export default useTopbanner
