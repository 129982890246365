import { ArchiveItem, ConvertedArchiveItem } from '@/types/archives'
import { MEMEX_URLS, createMemexFetcher } from '@/utils/memexFetcher'
import { pipe } from '@fxts/core'
import { useQuery } from 'react-query'

export type FilterKey =
  | 'category_main'
  | 'category_sub'
  | 'location_category'
  | 'period_category'

type Filters = Record<FilterKey, Array<string>>

const useArchives = ({
  filters,
  searchKeyword,
}: {
  filters?: Filters
  searchKeyword?: string
}) => {
  return useQuery({
    queryKey: ['archives'],
    queryFn: async () => {
      const memexFetcher = createMemexFetcher()

      return await memexFetcher.post(MEMEX_URLS.GET_LISTS.ARCHIVES, {
        size: 900,
        page: 0,
      })
    },
    select(data) {
      const converted = pipe(
        data,
        (data) => data.data.list,
        (list) => list.map(({ uid, data }) => ({ uid, ...data })),
        (list) => list.map((item) => convertArchiveItem(item))
      )

      if (filters && searchKeyword?.length >= 1) {
        const searchedList = pipe(adaptFilters(converted, filters), (list) =>
          searchItems(list, searchKeyword)
        )

        return searchedList as unknown as Array<ConvertedArchiveItem>
      }

      if (filters) {
        return adaptFilters(converted, filters) as Array<ConvertedArchiveItem>
      }

      if (searchKeyword?.length >= 1) {
        return searchItems(
          converted,
          searchKeyword
        ) as Array<ConvertedArchiveItem>
      }

      return converted as Array<ConvertedArchiveItem>
    },
    refetchOnWindowFocus: false,
  })
}

const convertArchiveItem = (archiveItem: ArchiveItem) => {
  return Object.entries(archiveItem).reduce((acc, [key, value]) => {
    acc[key] = value

    if (value && value['KO'] !== undefined) {
      acc[key] = value['KO']
    }

    if (
      key === 'location_category' ||
      key === 'period_category' ||
      key === 'category_main' ||
      key === 'category_sub'
    ) {
      acc[key] = value[0] ? value[0].languageMap['KO'] : ''
    }

    if (key === 'images') {
      acc[key] = value.map((imageItem) => imageItem.languageMap['KO'])
    }

    return acc
  }, {})
}

const adaptFilters = (
  converted: Array<ConvertedArchiveItem>,
  filters: Filters
) => {
  return converted.filter((archiveItem) => {
    // 아카이브 아이템이 걸러야 할 대상인지 파악한다
    // 먼저 필터키들을 확인, 아카이브아이템의 해당 밸류가 필터어레이에 포함되어 있는지 확인한다

    const filterEntries = Object.entries(filters)

    for (let i = 0; i < filterEntries.length; i++) {
      const [filterKey, allowedValues] = filterEntries[i]

      if (allowedValues.includes(archiveItem[filterKey])) return true
    }

    return false
  })
}

const searchItems = (
  list: Array<ConvertedArchiveItem>,
  searchKeyword: string
) => {
  return list.filter((item) => item.title.includes(searchKeyword))
}

export default useArchives
