import Layout from '@/components/Layout'
import NoticesList from '@/components/notices/NoticesList'

const NoticesActivities = () => {
  return (
    <Layout full>
      <NoticesList title="대덕마을기록단 활동" noticeType="activities" />
    </Layout>
  )
}

export default NoticesActivities
