import Layout from '@/components/Layout'
import NoticesItem from '@/components/notices/NoticesItem'
import { modelNoticesArchivesDevKey } from '@/configs/contents'

const NoticesArchivesView = () => {
  return (
    <Layout full>
      <NoticesItem
        title="대덕문화원 아카이브 사업"
        path="archives"
        devKey={modelNoticesArchivesDevKey}
      />
    </Layout>
  )
}

export default NoticesArchivesView
