/* eslint-disable react/display-name */
import { ReactNode } from 'react'
import { ConvertedRecordItem } from '@/types/record'

import DateFormat from '../DateFormat'
import History from '@/components/records/common/History'
import usePeople from '@/hooks/usePeople'

type Props = {
  item: ConvertedRecordItem
}

function RecordsInfoInterviewer({ item }: Props) {
  const { data: intervieweeData } = usePeople([item.interviewee])

  if (!item || !intervieweeData) return <></>

  const interviewee = intervieweeData[0]

  return (
    <div className="p-4 md:p-5">
      <div className="space-y-2 5 leading-[19.6px] text-[14px]">
        <div className="flex">
          <RecordsInfoInterviewer.Label>성명</RecordsInfoInterviewer.Label>
          <div className="pl-3">
            <p className="mb-0 color-black">
              {interviewee.name} {interviewee.name_cn}
            </p>
          </div>
        </div>
        <div className="flex">
          <RecordsInfoInterviewer.Label>성별</RecordsInfoInterviewer.Label>
          <div className="pl-3">
            <p className="mb-0 color-black">{interviewee.gender ?? '-'}</p>
          </div>
        </div>
        <div className="flex">
          <RecordsInfoInterviewer.Label>출생지</RecordsInfoInterviewer.Label>
          <div className="pl-3">
            <p className="mb-0 color-black">{interviewee.address}</p>
          </div>
        </div>
        <div className="flex">
          <RecordsInfoInterviewer.Label>생년월일</RecordsInfoInterviewer.Label>
          <div className="pl-3">
            <p className="mb-0 color-black">
              <span className="font-noto-sans-mono">
                <DateFormat str={interviewee.dob} />
              </span>{' '}
              ({interviewee.dob_type})
            </p>
          </div>
        </div>
        <div className="flex">
          <RecordsInfoInterviewer.Label>최종학력</RecordsInfoInterviewer.Label>
          <div className="pl-3">
            <p className="mb-0 color-black">{interviewee.education}</p>
          </div>
        </div>
        <div className="flex">
          <RecordsInfoInterviewer.Label>직업</RecordsInfoInterviewer.Label>
          <div className="pl-3">
            <p
              className="mb-0 color-black"
              dangerouslySetInnerHTML={{
                __html: interviewee.job.replace(/\n/g, '<br />'),
              }}
            />
          </div>
        </div>
        <div className="flex">
          <RecordsInfoInterviewer.Label>주요약력</RecordsInfoInterviewer.Label>
          <div className="pl-3">
            <ul className="space-y-1">
              {interviewee.history.split('\n').map((history, hIdx) => (
                <History key={`${history}-${hIdx}`} history={history} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

RecordsInfoInterviewer.Label = ({ children }: { children: ReactNode }) => {
  return <div className="flex-none font-bold leading-5 w-28">{children}</div>
}

export default RecordsInfoInterviewer
