import { useRef } from 'react'
import Slider from 'react-slick'
import MainKeywords from './MainKeywords'
import MainSearch from './MainSearch'
import useMain from '@/hooks/useMain'
import Loading from '@/components/Loading'

const configSlider = {
  dots: true,
  lazyLoad: false,
  autoplay: true,
  arrows: false,
  infinite: true,
  swipe: true,
  vertical: false,
  verticalSwiping: false,
  swipeToSlide: false,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  className: 'slick-image',
  autoplaySpeed: 5000,
  pauseOnHover: false,
  fade: true,
}

const MainSlider = () => {
  const { data: mainContents } = useMain()

  const sliderRef = useRef<Slider>(null)

  if (!mainContents) return <Loading hasLayout={false} />

  return (
    <div className="relative">
      <Slider ref={sliderRef} {...configSlider}>
        {mainContents.slideshow.map((imageSource, idx) => (
          <div key={idx}>
            <img
              src={imageSource.path}
              alt={`${imageSource.path}`}
              className="object-cover w-full h-[600px]"
            />
          </div>
        ))}
      </Slider>

      <MainKeywords />
      <MainSearch />
    </div>
  )
}

export default MainSlider
