import useArchives from '@/hooks/useArchives'
import useMain from '@/hooks/useMain'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router'

const keywordNames = [
  '사건',
  '문헌',
  '장소',
  '유물',
  '회덕동',
  '신탄진동',
  '석봉동',
  '1980년대',
  '2000년대',
  '2010년대',
]

const groupCount = 3
let groupLoop = 0
let groupInterval: any = null

const MainKeywords = () => {
  const navigate = useNavigate()
  const { data: mainContents } = useMain()
  const { data: archives } = useArchives({})

  const keywords = useMemo(() => {
    if (!mainContents || !archives) return []

    const selectedKeywords = mainContents.keywords.map((contentTitle) => {
      return archives.find((archive) => archive.title === contentTitle)
    })

    // Choose 10 random keywords if there are less than 10 keywords
    const randomKeywords = []
    for (let i = 0; i < 10; i++) {
      randomKeywords.push(
        selectedKeywords[Math.floor(Math.random() * selectedKeywords.length)]
      )
    }

    return randomKeywords
  }, [mainContents, archives])

  // Effect
  useEffect(() => {
    if (keywords && keywords.length) {
      playNextKeyword()

      if (groupInterval) {
        clearInterval(groupInterval)
      }

      groupInterval = setInterval(() => {
        playNextKeyword()
      }, 3000)
    }
  }, [keywords])

  /**
   * 키워드 애니메이션 재생
   */
  const playNextKeyword = () => {
    const currentGroupIdx = groupLoop % groupCount
    const keywords = document.querySelectorAll('.home-keyword-item')

    keywords.forEach((keyword, kIdx) => {
      keyword.classList.remove('show-keyword')

      const currentKeywords = document.querySelectorAll(
        '.home-keyword-group-' + currentGroupIdx
      )
      currentKeywords.forEach((currentKeyword) => {
        currentKeyword.classList.add('show-keyword')
      })
    })

    groupLoop = groupLoop + 1
  }

  return (
    <div>
      {keywords.map((keyword, hIdx) => (
        <div key={`main-keyword-${hIdx}-${keyword?.uid ?? ''}`}>
          {keyword && (
            <div
              className={`home-keyword-item home-keyword-group-${
                hIdx % groupCount
              } home-keyword-item-${hIdx + 1}`}
              onClick={() => {
                // setSelectedFilter((prev) => [...prev, keyword])
                navigate(`/archives/list?uid=${keyword.uid}`)
              }}>
              <div className="dot"></div>
              <div className="name">{keyword.title}</div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default MainKeywords
