import Layout from '@/components/Layout'
import ArchivesList from '@/components/archives/ArchivesList'
import ArchivesNav from '@/components/archives/ArchivesNav'

const ArchivesViewByList = () => {
  return (
    <Layout full overflow="auto">
      <ArchivesNav />
      <ArchivesList />
    </Layout>
  )
}

export default ArchivesViewByList
