import clsx from 'clsx'

const History = ({ history }: { history: string }) => {
  const [startedAt, endAt, title] = saturate(history).split(',')

  return (
    <li className="mb-[8px] flex">
      <div
        className={clsx(
          'bg-secondary',
          'px-[4px] pb-[2px]',
          'rounded-[2px]',
          'h-fit'
        )}>
        {formatDate(startedAt)}
        {!!endAt?.trim() && `-${formatDate(endAt)}`}
      </div>
      <div className="ml-[6px]">{title}</div>
    </li>
  )
}

function saturate(history: string) {
  return history.replaceAll(`"`, ``)
}

function formatDate(date: string) {
  const trimmed = date.trim()

  // ex) 201806
  if (trimmed.length === 6) {
    return trimmed.slice(0, 4) + '/' + trimmed.slice(4)
  }

  return trimmed
}

export default History
