export enum ActionTypesLayout {
  TOGGLE_NAV = 'TOGGLE_NAV', // Nav 설정
}

export type ActionsLayout = LayoutToggleGnb

export interface LayoutToggleGnb {
  type: ActionTypesLayout.TOGGLE_NAV
  payload: {
    nav: boolean
  }
}
