import {
  ArchiveInterface,
  ContentsInterface,
  HomeDataInterface,
  ImagesInterface,
  MainInterface,
  PeopleInterface,
  SurveyListInterface,
  VideosInterface,
} from '@/types'

import { RecordsInterface } from '@/types/record'

export enum ActionTypesContents {
  SET_LOADED = 'SET_LOADED', // 로딩 설정
  SET_ARCHIVES_LIST = 'SET_ARCHIVES_LIST', // 도시기록문화아카이브 설정
  SET_RECORDS_LIST = 'SET_RECORDS_LIST', // 구술채록 설정
  SET_PEOPLE_LIST = 'SET_PEOPLE_LIST', // 구술_인물 설정
  SET_CONTENTS_LIST = 'SET_CONTENTS_LIST', // 구술_콘텐츠 설정
  SET_IMAGES_LIST = 'SET_IMAGES_LIST', // 이미지 자료 설정
  SET_VIDEOS_LIST = 'SET_VIDEOS_LIST', // 영상 자료 설정
  SET_FILTER = 'SET_FILTER', // 도시기록문화아카이브 필터 설정
  SET_HOME = 'SET_HOME', // 홈 데이터 설정
  SET_MAIN = 'SET_MAIN', // 메인 데이터 설정
  SET_SURVEYLIST = 'SET_SURVEYLIST', // 과제목록 데이터 설정
}

export type ActionsContents =
  | ContentsSetLoaded
  | ContentsSetArchivesList
  | ContentsSetRecordsList
  | ContentsSetPeopleList
  | ContentsSetContentsList
  | ContentsSetImagesList
  | ContentsSetVideosList
  | ContentsSetFilter
  | ContentsSetHome
  | ContentsSetMain
  | ContentsSetSurveyList

export interface ContentsSetLoaded {
  type: ActionTypesContents.SET_LOADED
  payload: {
    loaded: boolean
  }
}

export interface ContentsSetArchivesList {
  type: ActionTypesContents.SET_ARCHIVES_LIST
  payload: {
    list: ArchiveInterface[]
  }
}

export interface ContentsSetRecordsList {
  type: ActionTypesContents.SET_RECORDS_LIST
  payload: {
    list: RecordsInterface[]
  }
}

export interface ContentsSetPeopleList {
  type: ActionTypesContents.SET_PEOPLE_LIST
  payload: {
    list: PeopleInterface[]
  }
}

export interface ContentsSetContentsList {
  type: ActionTypesContents.SET_CONTENTS_LIST
  payload: {
    list: ContentsInterface[]
  }
}

export interface ContentsSetImagesList {
  type: ActionTypesContents.SET_IMAGES_LIST
  payload: {
    list: ImagesInterface[]
  }
}

export interface ContentsSetVideosList {
  type: ActionTypesContents.SET_VIDEOS_LIST
  payload: {
    list: VideosInterface[]
  }
}

export interface ContentsSetFilter {
  type: ActionTypesContents.SET_FILTER
  payload: {
    filters: string[]
    keyword: string
  }
}

export interface ContentsSetHome {
  type: ActionTypesContents.SET_HOME
  payload: {
    data: HomeDataInterface
  }
}

export interface ContentsSetMain {
  type: ActionTypesContents.SET_MAIN
  payload: {
    list: MainInterface[]
  }
}

export interface ContentsSetSurveyList {
  type: ActionTypesContents.SET_SURVEYLIST
  payload: {
    list: SurveyListInterface[]
  }
}
