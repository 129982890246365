import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import logger from 'redux-logger'
import rootReducer from '@/states/reducers'

let dispatch

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV !== 'production'
      ? getDefaultMiddleware({
          immutableCheck: false,
          serializableCheck: false,
        })
      : getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== 'production',
})

export type AppDispatch = typeof dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
