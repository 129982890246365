/* eslint-disable react/display-name */
import { ConvertedRecordItem } from '@/types/record'
import { ReactNode } from 'react'

import History from '@/components/records/common/History'
import usePeople from '@/hooks/usePeople'
import DateFormat from '../DateFormat'

type Props = {
  item: ConvertedRecordItem
}

function RecordsInfoInterviewee({ item }: Props) {
  const { data: intervieweeData } = usePeople([item.interviewee])

  if (!item || !intervieweeData) return <></>

  const interviewee = intervieweeData[0]

  return (
    <div className="p-4 md:p-5">
      <div className="space-y-2 5 leading-[19.6px] text-[14px]">
        <div className="flex">
          <RecordsInfoInterviewee.Label>성명</RecordsInfoInterviewee.Label>
          <div className="pl-3">
            <p className="mb-0 color-black">
              {interviewee.name} {interviewee.name_cn}
            </p>
          </div>
        </div>
        <div className="flex">
          <RecordsInfoInterviewee.Label>성별</RecordsInfoInterviewee.Label>
          <div className="pl-3">
            <p className="mb-0 color-black">{interviewee.gender ?? '-'}</p>
          </div>
        </div>
        <div className="flex">
          <RecordsInfoInterviewee.Label>출생지</RecordsInfoInterviewee.Label>
          <div className="pl-3">
            <p className="mb-0 color-black">{interviewee.address}</p>
          </div>
        </div>
        <div className="flex">
          <RecordsInfoInterviewee.Label>생년월일</RecordsInfoInterviewee.Label>
          <div className="pl-3">
            <p className="mb-0 color-black">
              <span className="font-noto-sans-mono">
                <DateFormat str={interviewee.dob} />
              </span>{' '}
              ({interviewee.dob_type})
            </p>
          </div>
        </div>
        <div className="flex">
          <RecordsInfoInterviewee.Label>가족사항</RecordsInfoInterviewee.Label>
          <div className="pl-3">
            <p className="mb-0 color-black">{interviewee.family}</p>
          </div>
        </div>
        <div className="flex">
          <RecordsInfoInterviewee.Label>최종학력</RecordsInfoInterviewee.Label>
          <div className="pl-3">
            <p className="mb-0 color-black">{interviewee.education}</p>
          </div>
        </div>
        <div className="flex">
          <RecordsInfoInterviewee.Label>종교</RecordsInfoInterviewee.Label>
          <div className="pl-3">
            <p className="mb-0 color-black">{interviewee.religion}</p>
          </div>
        </div>
        <div className="flex">
          <RecordsInfoInterviewee.Label>직업</RecordsInfoInterviewee.Label>
          <div className="pl-3">
            <p
              className="mb-0 color-black"
              dangerouslySetInnerHTML={{
                __html: interviewee.job.replace(/\n/g, '<br />'),
              }}
            />
          </div>
        </div>
        <div className="flex">
          <RecordsInfoInterviewee.Label>주요약력</RecordsInfoInterviewee.Label>
          <div className="pl-3">
            <ul>
              {interviewee.history.split('\n').map((history, historyIndex) => (
                <History key={`${history}-${historyIndex}`} history={history} />
              ))}
            </ul>
          </div>
        </div>

        <div className="flex">
          <RecordsInfoInterviewee.Label>
            도움주신 분
          </RecordsInfoInterviewee.Label>
          <div className="pl-3">
            <p className="mb-0 color-black">{interviewee.help_name}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

RecordsInfoInterviewee.Label = ({ children }: { children: ReactNode }) => {
  return <div className="flex-none font-bold leading-5 w-28">{children}</div>
}

export default RecordsInfoInterviewee
