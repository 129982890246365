import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Layout from '@/components/Layout'
import ArchivesList from '@/components/archives/ArchivesList'
import ArchivesMap from '@/components/archives/ArchivesMap'
import { ARCHIVE_MAP_ZOOM, DEFAULT_MAP_COORDINATES } from '@/configs/contents'
import ArchivesNav from '@/components/archives/ArchivesNav'
import ArchivesFilter from '@/components/archives/ArchivesFilter'

const initZoom = 11

const ArchivesViewByMap = () => {
  const navigate = useNavigate()
  const location = useLocation()

  // State
  const [zoom, setZoom] = useState<number>(initZoom)

  useEffect(() => {
    // 해상도별 Zoom 값 설정
    const _w = window.innerWidth
    let targetZoom = initZoom

    ARCHIVE_MAP_ZOOM.forEach((zoomObj) => {
      if (_w >= zoomObj.width) {
        targetZoom = zoomObj.zoom
      }
    })

    setZoom(targetZoom)
  }, [])

  return (
    <Layout full noFooter overflow="hidden">
      <ArchivesNav />
      {!!zoom && (
        <ArchivesMap
          centerCoors={DEFAULT_MAP_COORDINATES}
          zoom={zoom}
          scroll={true}
          move={true}
        />
      )}
    </Layout>
  )
}

export default ArchivesViewByMap
