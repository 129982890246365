import clsx from 'clsx'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { atom, useRecoilValue, useSetRecoilState } from 'recoil'

import Loading from '@/components/Loading'
import SubHeader from '@/components/main/common/SubHeader'
import useArchives from '@/hooks/useArchives'
import useImages from '@/hooks/useImages'
import useIsMobile from '@/hooks/useIsMobile'
import useMain from '@/hooks/useMain'
import { ConvertedArchiveItem } from '@/types/archives'

const MainArchives = () => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  const { data: mainContents } = useMain()
  const { data: archives } = useArchives({})

  if (!mainContents || !archives) return <Loading hasLayout={false} />

  const stories = mainContents.story_content.map((contentTitle) => {
    return archives.find((archive) => archive.title === contentTitle)
  })

  return (
    <div className="pl-5 lg:pl-[100px]">
      <div
        className={clsx(
          'flex items-center justify-between',
          'pb-5 mb-10 mr-[100px] maxmd:mr-[20px]',
          'border-b border-primary'
        )}>
        <SubHeader>대덕구 이야기</SubHeader>

        <div
          className="flex items-center font-bold cursor-pointer color-primary"
          onClick={() => {
            navigate('/archives/list', {
              state: {
                scrollTop: true,
              },
            })
          }}>
          {isMobile ? (
            <span>더 보기</span>
          ) : (
            <span>더 많은 이야기 보러가기</span>
          )}
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M4 11V1L10 6L4 11Z" fill="#3B6161" />
          </svg>
        </div>
      </div>

      <div className="relative">
        {/* 대덕구 이야기 캐로우셀 */}
        <MainArchives.Stories stories={stories} />
      </div>
    </div>
  )
}

MainArchives.Stories = ({
  stories,
}: {
  stories: Array<ConvertedArchiveItem>
}) => {
  const isMobile = useIsMobile()

  const [containerRef, setContainerRef] = useState(null)
  const currentSlideRef = useRef<null | HTMLLIElement>(null)

  const archiveItemElements = useRecoilValue(archiveItemElementsState)

  useEffect(() => {
    if (!containerRef) return
    if (Object.values(archiveItemElements).length <= 0) return

    const observer = new IntersectionObserver(
      (entries, observer) => {
        if (entries[0].isIntersecting) {
          currentSlideRef.current = entries[0].target as HTMLLIElement
        }
      },
      {
        root: containerRef,
        threshold: 1.0,
      }
    )

    Object.values(archiveItemElements).forEach((el) => {
      observer.observe(el as Element)
    })
  }, [containerRef, archiveItemElements])

  const scrollRecordsLeft = (scrollLeft) => {
    const records = document.getElementById('main-records-list')
    if (records) {
      records.scrollLeft += scrollLeft
    }
  }

  const handleClickSliderButton = (direction: 'left' | 'right') => () => {
    if (direction === 'left') {
      ;(
        currentSlideRef.current.previousSibling as HTMLLIElement
      ).scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
        inline: 'center',
      })

      currentSlideRef.current = currentSlideRef.current
        .previousSibling as HTMLLIElement
    }

    if (direction === 'right') {
      ;(currentSlideRef.current.nextSibling as HTMLLIElement).scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
        inline: 'center',
      })

      currentSlideRef.current = currentSlideRef.current
        .nextSibling as HTMLLIElement
    }
  }

  return (
    <div
      id="main-records-list"
      className="w-full overflow-x-auto scroll-smooth hide-scrollbar">
      <ul
        className="flex space-x-10 w-max maxmd:w-full maxmd:overflow-scroll"
        ref={setContainerRef}>
        {useMemo(() => {
          return stories.map((item, index) => (
            <MainArchives.ArchiveItem
              key={`record-item-${item.uid}`}
              item={item}
              index={index}
            />
          ))
        }, [stories])}
      </ul>
      <button
        type="button"
        className="bg-[#6C6C6C] rounded-full absolute top-1/2 -translate-y-1/2 -translate-x-16 maxmd:-translate-x-[10%] left-0 w-12 h-12 flex justify-center items-center"
        onMouseOver={() => {
          if (isMobile) return

          scrollRecordsLeft(-window.innerWidth)
        }}
        onClick={handleClickSliderButton('left')}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M8 2L2 8L8 14" stroke="#F0F0F0" strokeWidth="2" />
          <path d="M2 8H15" stroke="#F0F0F0" strokeWidth="2" />
        </svg>
      </button>

      <button
        type="button"
        className="bg-[#6C6C6C] rounded-full absolute top-1/2 -translate-y-1/2 right-5 maxmd:translate-x-[10%] w-12 h-12 flex justify-center items-center"
        onMouseOver={() => {
          if (isMobile) return

          scrollRecordsLeft(window.innerWidth)
        }}
        onClick={handleClickSliderButton('right')}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          className="transform rotate-180"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M8 2L2 8L8 14" stroke="#F0F0F0" strokeWidth="2" />
          <path d="M2 8H15" stroke="#F0F0F0" strokeWidth="2" />
        </svg>
      </button>
    </div>
  )
}

const archiveItemElementsState = atom({
  key: 'archiveItemElementsState',
  default: {},
})

MainArchives.ArchiveItem = ({
  item,
  index,
}: {
  item: ConvertedArchiveItem
  index: number
}) => {
  const navigate = useNavigate()

  const { data: images } = useImages(item.images)

  const setArchiveItemElementsState = useSetRecoilState(
    archiveItemElementsState
  )

  if (!images || !images[0] || !images[0].files || !images[0].files[0])
    return <></>

  const imageSource = images[0].files[0].path ?? ''

  return (
    <li
      ref={(ref) => {
        setArchiveItemElementsState((prev) => {
          const newValue = { ...prev }
          newValue[index] = ref

          return newValue
        })
      }}
      className="min-w-[320px] lg:w-[540px] mb-[1.5em] cursor-pointer"
      onClick={() => {
        navigate(`/archives/list?uid=${item.uid}`, {
          state: {
            scrollTop: true,
          },
        })
      }}>
      <div className="mb-5">
        {imageSource && (
          <img
            src={imageSource}
            alt=""
            className="object-cover w-[540px]"
            style={{
              aspectRatio: '540/320',
            }}
          />
        )}
      </div>
      <div>
        <h3 className="mb-1 text-lg font-bold">{item.title}</h3>
        <p className="line-clamp-2">{item.desc}</p>
      </div>
    </li>
  )
}

export default MainArchives
