import clsx from 'clsx'
import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { atom, useRecoilValue } from 'recoil'

import Loading from '@/components/Loading'
import ArchivesInfo from '@/components/archives/ArchivesInfo'
import ArchivesItem from '@/components/archives/ArchivesItem'
import {
  archiveSearchKeywordState,
  selectedFiltersState,
} from '@/components/archives/ArchivesNav'
import useArchives from '@/hooks/useArchives'
import useImages from '@/hooks/useImages'
import { assembleArchiveFilter } from '@/utils'

export const isLoadingArchiveItemsState = atom({
  key: 'isLoadingArchiveItemsState',
  default: true,
})

const ArchivesList = () => {
  const isLoadingArchiveItems = useRecoilValue(isLoadingArchiveItemsState)
  const selectedFilters = useRecoilValue(selectedFiltersState)
  const searchKeyword = useRecoilValue(archiveSearchKeywordState)

  const filters = assembleArchiveFilter(selectedFilters)

  const { data: archiveList } = useArchives({ filters, searchKeyword })
  const { data: images } = useImages(
    (archiveList ?? []).flatMap((archive) => archive.images)
  )

  const location = useLocation()

  useEffect(() => {
    if (!location.state) return

    const { scrollTop } = location.state

    if (scrollTop) {
      document.body.scrollTop = 0
    }
  }, [location.state])

  if (!archiveList) return <></>

  return (
    <div>
      <ul
        className={clsx(
          'flex flex-wrap',
          'w-[calc(621px*2)] maxmd:w-[calc(100vw-40px)] mx-auto my-[142px]',
          'border-[1px] border-b-[0px] border-r-[0px] border-primary',
          (isLoadingArchiveItems || archiveList.length <= 0) && 'border-t-[0px]'
        )}>
        {(archiveList ?? []).map((archive) => (
          <ArchivesItem
            item={archive}
            images={images ?? []}
            key={archive.uid}
          />
        ))}
      </ul>

      {archiveList && <ArchivesInfo />}

      {isLoadingArchiveItems && <Loading hasLayout={false} />}
    </div>
  )
}

export default ArchivesList
