import axios from 'axios'
import { projectUid } from '@/configs/contents'
import { ActionTypesContents } from '../interfaces'

/**
 * 데이터 불러오기
 * @returns
 */
export const getContentsDataList = (req, modelKey) => {
  return axios.post(
    `${process.env.REACT_APP_MEMEX_API}/projects/${projectUid}/models/${modelKey}/contents/search/v1`,
    req,
    {
      headers: {
        'Content-Type': 'application/json',
        'Access-Token': process.env.REACT_APP_MEMEX_APP_KEY as string,
      },
    }
  )
}

/**
 * 데이터 하나 불러오기
 * @param modelKey
 * @param uid
 * @returns
 */
export const getContentsDataItem = (uid, modelKey) => {
  return axios.get(
    `${process.env.REACT_APP_MEMEX_API}/projects/${projectUid}/models/${modelKey}/contents/${uid}/v1`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Access-Token': process.env.REACT_APP_MEMEX_APP_KEY as string,
      },
    }
  )
}

/**
 * 로딩 설정
 * @param loaded
 */
export const setLoaded = (loaded: boolean) => (dispatch) => {
  return dispatch({
    type: ActionTypesContents.SET_LOADED,
    payload: {
      loaded: loaded,
    },
  })
}

/**
 * 도시기록문화아카이브 목록 설정
 */
export const setArchivesList = (data) => (dispatch) => {
  return dispatch({
    type: ActionTypesContents.SET_ARCHIVES_LIST,
    payload: {
      list: data,
    },
  })
}

/**
 * 도시기록문화아카이브 데이터 필터 설정
 * @param filters
 * @param keyword
 */
export const setArchiveFilter =
  (filters: string[] | undefined, keyword: string | string[] | undefined) =>
  (dispatch) => {
    return dispatch({
      type: ActionTypesContents.SET_FILTER,
      payload: {
        filters,
        keyword,
      },
    })
  }

/**
 * 구술채록 목록 설정
 */
export const setRecordsList = (data) => (dispatch) => {
  return dispatch({
    type: ActionTypesContents.SET_RECORDS_LIST,
    payload: {
      list: data,
    },
  })
}

/**
 * 구술_인물 목록 설정
 */
export const setPeopleList = (data) => (dispatch) => {
  return dispatch({
    type: ActionTypesContents.SET_PEOPLE_LIST,
    payload: {
      list: data,
    },
  })
}

/**
 * 구술_콘텐츠 목록 설정
 */
export const setContentsList = (data) => (dispatch) => {
  return dispatch({
    type: ActionTypesContents.SET_CONTENTS_LIST,
    payload: {
      list: data,
    },
  })
}

/**
 * 이미지 자료 목록 설정
 */
export const setImagesList = (data) => (dispatch) => {
  return dispatch({
    type: ActionTypesContents.SET_IMAGES_LIST,
    payload: {
      list: data,
    },
  })
}

/**
 * 영상 자료 목록 설정
 */
export const setVideosList = (data) => (dispatch) => {
  return dispatch({
    type: ActionTypesContents.SET_VIDEOS_LIST,
    payload: {
      list: data,
    },
  })
}

/**
 * 홈 데이터설정
 */
export const setHomeData = (data) => (dispatch) => {
  return dispatch({
    type: ActionTypesContents.SET_HOME,
    payload: {
      data: data,
    },
  })
}

/**
 * 메인 데이터설정
 */
export const setMainData = (data) => (dispatch) => {
  return dispatch({
    type: ActionTypesContents.SET_MAIN,
    payload: {
      list: data,
    },
  })
}

/**
 * 과제목록 데이터설정
 */
export const setSurveyListData = (data) => (dispatch) => {
  return dispatch({
    type: ActionTypesContents.SET_SURVEYLIST,
    payload: {
      list: data,
    },
  })
}
